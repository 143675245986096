.mat-snack-bar-container {
  color: black !important;
  background: transparent !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  margin: 0 !important;
  max-width: initial !important;
  min-width: initial !important;
  min-height: initial !important;
  max-height: initial !important;
}

.mat-accordion {
  &.clean {
    padding: 0;

    .mat-expansion-panel {
      border-radius: 0 !important;
      box-shadow: none;
      margin: 0;
      background: transparent;

      .mat-expansion-panel-header {
        padding: 0;
        height: auto;

        .mat-content {
          display: flex;
          height: 100%;
          width: 100%;
          margin: 0;
          font-weight: normal;
        }
      }

      .mat-expansion-panel-content {
        margin: 0.5rem !important;
        .mat-expansion-panel-body {
          padding: 0;
        }
      }
    }
  }

  &.compact {
    .mat-expansion-panel {
      .mat-expansion-panel-content {
        margin-left: 24px;

        .mat-expansion-panel-body {
          padding: 0;
          margin-left: 0px;
        }
      }
    }
  }

  &.plain {
    .mat-expansion-panel {
      .mat-expansion-panel-content {
        margin: 0px !important;
        border-left: 0px;

        .mat-expansion-panel-body {
          padding: 0;
          margin-left: 0px;
        }
      }
    }
  }

  &.table {
    .mat-expansion-panel {
      .mat-expansion-panel-content {
        margin-left: 0.5rem !important;
        border-left: 0px;

        .mat-expansion-panel-body {
          padding: 0;
          margin-left: 0px;
        }
      }
    }
  }
}

.cdk-overlay-container {
  .cdk-global-overlay-wrapper {
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    background: rgba(0, 0, 0, 0.32);
  }

  .cdk-overlay-backdrop {
    display: flex;
    background: transparent;
  }

  .cdk-global-overlay-wrapper {
    .mat-mdc-dialog-panel {
      outline: none !important;

      mat-dialog-container.mat-mdc-dialog-container {
        background: transparent;

        .mat-mdc-dialog-surface {
          background: transparent;
          border-radius: 0;
          box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.16);
        }
      }

      &.rounded-50 {
        mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface {
          border-radius: 0.5rem;
        }
      }
    }
  }
}

.cdk-overlay-connected-position-bounding-box {
  top: 0 !important;
  left: 0 !important;
}

.mdc-snackbar__surface {
  padding: 0 !important;

  .mat-mdc-snack-bar-label {
    padding: 0 !important;
  }
}

.cdk-overlay-pane:has(> .mat-mdc-select-panel) {
  min-width: 350px;
  width: unset !important;
}

.table > :not(caption) > * > * {
  padding: 0px !important;
}

.mdc-button {
  padding: var(--mat-outlined-button-vertical-padding, 15px) var(--mat-outlined-button-horizontal-padding, 15px) !important;
}

html {
  --mdc-outlined-button-label-text-size: 13px;
  --mdc-outlined-button-container-height: auto;
  --mdc-outlined-button-container-shape: 4px;
  --mat-outlined-button-horizontal-padding: 10px;
  --mat-outlined-button-vertical-padding: 5px;
  --mdc-circular-progress-active-indicator-color: var(--primary);
  --mdc-outlined-button-label-text-color: var(--primary);
  --mat-radio-checked-ripple-color: var(--primary);
  --mdc-radio-selected-icon-color: var(--primary);
  --mdc-radio-selected-focus-icon-color: var(--primary);
  --mdc-checkbox-selected-icon-color: var(--primary);
  --mdc-checkbox-selected-focus-icon-color: var(--primary);
  --mdc-checkbox-selected-hover-icon-color: var(--primary);
  --mat-dialog-container-max-width: unset;
  --mdc-outlined-button-container-shape: 6px;
  --mdc-radio-selected-hover-icon-color: var(--primary);
  --mdc-radio-selected-pressed-icon-color: var(--primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--primary);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary);
  --mat-outlined-button-state-layer-color: var(--primary);
  --mat-text-button-ripple-color: var(--ripple-color);
  --mat-protected-button-ripple-color: var(--ripple-color);
  --mat-outlined-button-ripple-color: var(--ripple-color);
  --mat-datepicker-calendar-date-today-outline-color: var(--primary);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--primary);
  --mat-datepicker-calendar-date-today-outline-color: var(--primary);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--primary);
  --mat-datepicker-calendar-date-selected-state-background-color: #fff;
  --mat-datepicker-calendar-date-selected-state-text-color: var(--primary);
  --mat-dialog-container-max-width: 80svw;

  .mat-ripple-element {
    background-color: var(--ripple-color);
  }
}
